import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import ExportTermsBasket from "../../components/Terms/ExportTermsBasket";
import {
  searchTranslationTermForVersion,
  updateTermInVersion,
} from "../translationsSlice";
import SimpleTermCard from "../../components/Terms/SimpleTerm";
import ListTermCard from "../../components/Terms/ListTerm";
import { ListTerm, SimpleTerm } from "../../common/types";

interface DispatchProps {
  searchTranslationTermForVersion: any;
  updateTermInVersion: any;
}

interface StateProps {
  termsList: any;
  languages: any[];
  termsToExport: any[];
}

interface OwnProps {
  history: any;
}

type TranslationTermsProps = DispatchProps & StateProps & OwnProps;

interface PageParams {
  version: string;
  translationPack: string;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TranslationTerms = (props: TranslationTermsProps) => {
  const { version } = useParams<PageParams>();
  const { termsList, languages, termsToExport, history, searchTranslationTermForVersion, updateTermInVersion } = props;
  const [termsToDisplay, setTermsToDisplay] = useState(termsList);
  const [filterText, setFilterText] = useState("");
  const query = useQuery();
  const searchTerm = query.get("q");

  const languagesKeys = languages.map(({ key }) => key);

  const updateTranslationsTerm = (key, translations) =>
    updateTermInVersion({
      data: {
        version,
        key,
        translations,
      },
    });
  const updateItemsTerm = (key, items) =>
    updateTermInVersion({
      data: {
        version,
        key,
        items,
      },
    });

  useEffect(() => {
    searchTranslationTermForVersion({
      data: {
        version,
      },
      params: {
        s: searchTerm,
        limit: 3000,
      },
    });
  }, [searchTerm, version]);

  useEffect(() => {
    if (filterText !== "") {
      setTermsToDisplay(
        termsToDisplay.filter((term) => term.key.includes(filterText))
      );
    } else {
      setTermsToDisplay(termsList);
    }
  }, [filterText, termsList]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={9}>
            <h3 style={{ textTransform: "capitalize" }}>
              Showing Results for "{searchTerm}"
            </h3>
          </Col>
          <Col>
            {version === "testing" && (
              <ExportTermsBasket version={version} history={history} />
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <InputGroup>
              <FormControl
                placeholder="Filter Translation Terms"
                aria-label="Filter Translation Terms"
                onKeyUp={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              {termsToDisplay.map((term) => {
                if (Array.isArray(term.items) && term.items.length > 0) {
                  return (
                    <ListTermCard
                      term={term as ListTerm}
                      languages={languagesKeys}
                      updateTerm={updateItemsTerm}
                      key={term._id || `list-${term.key}`}
                      version={version}
                      termsToExport={termsToExport}
                    />
                  );
                }
                return (
                  <SimpleTermCard
                    term={term as SimpleTerm}
                    languages={languagesKeys}
                    key={term._id || `simple-${term.key}`}
                    updateTerm={updateTranslationsTerm}
                    version={version}
                    termsToExport={termsToExport}
                  />
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  termsList: state.translations.termsList,
  languages: state.languages,
  termsToExport: state.exportTerms.termsToExport,
});
const mapDispatchToProps: DispatchProps = {
  searchTranslationTermForVersion,
  updateTermInVersion,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(TranslationTerms);

export default Wrapped;
