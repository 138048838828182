import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { apiCall } from "./helpers/apiCall";

export const fetchLanguages = createAsyncThunk(
  'fetchLanguages',
  async (data, thunkAPI) => {
    return await apiCall({
      url: 'languages'
    }, thunkAPI);
  }
);

const languagesSlice = createSlice({
  name: 'languages',
  initialState: [],
  reducers: {

  },
  extraReducers: {
    // @ts-ignore
    [fetchLanguages.fulfilled]: (state, action) => {
      if (action.payload) return action.payload;
    },
  }

});

export default languagesSlice.reducer
