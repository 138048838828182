import React, {useEffect, useState} from 'react';
import './Application.scss';
import { Card, Button, Form } from "react-bootstrap";
import {connect} from "react-redux";
import {addTranslationPack, fetchApplication, removeTranslationPack} from "../Applications/applicationsSlice";
import {useParams} from "react-router-dom";
import {isEmpty} from "lodash";
import {fetchTranslationPacks} from "../translationsSlice";

interface StateProps {
  currentApplication: any,
  translationPacks: any
}

interface DispatchProps {
  fetchApplication: any,
  fetchTranslationPacks: any,
  addTranslationPack: any,
  removeTranslationPack: any
}

interface OwnProps {
}

function Applications({
                        currentApplication,
                        fetchApplication,
                        fetchTranslationPacks,
                        addTranslationPack,
                        removeTranslationPack,
                        translationPacks,
                        history }) {
  const { name } = useParams();
  useEffect(() => {
    if (!translationPacks.length) fetchTranslationPacks();
    if (isEmpty(currentApplication)) fetchApplication({
      data: { name },
      callbackError: () => {
        history.push('/applications');
      }
    });
  }, []);
  const includedPacks = [];
  const notIncludedPacks = [];

  if (currentApplication.translationPacks) {
    translationPacks.forEach(pack => {
      if (currentApplication.translationPacks.includes(pack._id)) {
        includedPacks.push(pack);
      } else {
        notIncludedPacks.push(pack);
      }
    });
  }
  return (
    <div className={"Application"}>
      <h3>
        {name}
      </h3>
      {
        !!includedPacks.length && <>
            <h4>Included translation packs</h4>
            <div className="cards">
              {includedPacks.map(translationPack => (
                  <Card key={translationPack._id}>
                    <Card.Title>
                      {translationPack.key}
                    </Card.Title>
                    <Button variant="danger" size="lg" block
                            onClick={() => {
                              removeTranslationPack({
                                data: {
                                  id: currentApplication._id,
                                  translationPackId: translationPack._id
                                },
                                callback: () => {
                                  fetchApplication({
                                    data: { name }
                                  });
                                }
                              });
                            }}
                    >
                      Remove
                    </Button>
                  </Card>
                )
              )}
            </div>
        </>
      }
      {
        !!notIncludedPacks.length && <>
            <h4>All translation packs</h4>
            <div className="cards">
              {notIncludedPacks.map(translationPack => (
                  <Card key={translationPack._id}>
                    <Card.Title>
                      {translationPack.key}
                    </Card.Title>
                    <Button variant="primary" size="lg" block
                            onClick={() => {
                              addTranslationPack({
                                data: {
                                  id: currentApplication._id,
                                  translationPackId: translationPack._id
                                },
                                callback: () => {
                                  fetchApplication({
                                    data: { name }
                                  });
                                }
                              });
                            }}
                    >
                      Add
                    </Button>
                  </Card>
                )
              )}
            </div>
        </>
      }
    </div>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  currentApplication: state.applications.currentApplication,
  translationPacks: state.translations.translationPacks,

});

const mapDispatchToProps = {
  fetchApplication: fetchApplication,
  fetchTranslationPacks: fetchTranslationPacks,
  addTranslationPack: addTranslationPack,
  removeTranslationPack: removeTranslationPack
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Applications);

export default Wrapped;
