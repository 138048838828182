import axios from "axios";
import { showLoader, hideLoader } from "./../components/Loader/loaderState"

const API_URL = process.env.REACT_APP_API_URL || "https://localisation-api.neuroventis.care/";

export interface ApiPayload {
  data?: any,
  callback?: any,
  callbackError?: any,
  url?: string,
  method?: string,
  params?: any,
};

export const apiCall = async (payload: ApiPayload, thunkApi) => {
  const { url, method, data, callback, callbackError, params } = payload;
  try {
    thunkApi.dispatch(showLoader());
    // @ts-ignore
    const response = await axios({
      method,
      url: API_URL + url,
      data,
      params,
      withCredentials: true,
    });
    thunkApi.dispatch(hideLoader());
    if (response.status >= 200 && response.status < 300) {
      if (callback) callback(response.data);
      return response.data
    } else {
      if (callbackError) callbackError(response);
    }
  } catch (e) {
    thunkApi.dispatch(hideLoader())
    if (callbackError) callbackError(e);
  }
};
