import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { createUser, listUsers } from "../userSlice";
import CreateUserModal from "../../components/User/CreateUserModal";
import "./UserList.scss";

interface IUser {
  _id: string;
  email: string;
  role: string;
}
interface DispatchProps {
  listUsers: any;
  createUser: any;
}
interface OwnProps {}
interface StateProps {
  allUsers: IUser[];
}

type UserListProps = DispatchProps & OwnProps & StateProps;

const UserList = (props: UserListProps) => {
  const { allUsers, createUser, listUsers } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    variant: '',
    text: '',
  });

  useEffect(() => {
    listUsers({});
  }, [listUsers]);

  return (
    <>
      <CreateUserModal
        show={showCreateModal}
        onHideModal={() => {
          setShowCreateModal(false);
        }}
        createUser={(email, password) => {
          createUser({
            data: {
              email,
              password,
              role: 'Admin'
            }
          }).then(result => {
            setShowCreateModal(false);
            if (result.payload && result.payload === "Created") {
              setAlert({
                show: true,
                text: 'User created successfully.',
                variant: 'success'
              });
            }
          }).catch(() => {
            setShowCreateModal(false);
            setAlert({
              show: true,
              text: 'Cannot create User at this moment.',
              variant: 'danger'
            }); 
          });
        }}
      />
      <Container fluid>
        <Row>
          <Col>
            <h3>Users</h3>
          </Col>
        </Row>
        <Row>
          <Col className="user-actions">
            <Button
              variant="success"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Create Administrator User
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
          { alert.show && <Alert variant={alert.variant}>
              { alert.text }
          </Alert>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>E-mail address</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers.map((user) => (
                    <tr key={user._id}>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  allUsers: state.user.allUsers,
});
const mapDispatchToProps: DispatchProps = {
  createUser,
  listUsers,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(UserList);

export default Wrapped;
