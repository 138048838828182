import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from "axios";
import {apiCall, ApiPayload} from "../helpers/apiCall";

interface TranslationsState {
  translationPacks: any,
  selectedTranslationPack: any,
  termsList: any
}

const initialState = {
  translationPacks: [],
  selectedTranslationPack: {},
  termsList: []
} as TranslationsState

export const fetchTranslationPacks = createAsyncThunk(
  'fetchTranslationPacks',
  async (data, thunkAPI) => {
    return await apiCall({
      url: 'translations'
    }, thunkAPI);
  }
);

export const fetchTranslationPack = createAsyncThunk(
  'fetchTranslationPack',
  async (payload, thunkAPI) => {
    // @ts-ignore
    const { key } = payload;
    return await apiCall({
      // @ts-ignore
      ...payload,
      url: 'translations/key/' + key
    }, thunkAPI);
  }
);

export const updateTerms = createAsyncThunk<null, ApiPayload, null>(
  'updateTerms',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'put',
      url: 'translations/terms',
      ...payload,
    }, thunkAPI);
  }
);

export const createTerm = createAsyncThunk<null, ApiPayload, null>(
  'createTerm',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'terms',
      ...payload,
    }, thunkAPI);
  }
);

export const createTranslationPack = createAsyncThunk<null, ApiPayload, null>(
  'createTranslationPack',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'translations/',
      ...payload,
    }, thunkAPI);
  }
);

export const deleteTerm = createAsyncThunk<null, ApiPayload, null>(
  'deleteTerm',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'delete',
      url: 'translations/terms/id/' + payload.data.id,
      ...payload,
    }, thunkAPI);
  }
);

export const fetchTermsForVersion = createAsyncThunk<null, ApiPayload, null>(
  'fetchTermsForVersion',
  async (payload, thunkAPI) => {
    return await apiCall({
      url: `terms/name/${payload.data.versionName}/${payload.data.packName}`,
      ...payload,
    }, thunkAPI);
  }
);

export const updateTermsForVersion = createAsyncThunk<null, ApiPayload, null>(
  'updateTermsForVersion',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'patch',
      url: 'terms',
      ...payload,
    }, thunkAPI);
  }
);

export const searchTranslationTermForVersion = createAsyncThunk<null, ApiPayload, null>(
  'searchTranslationTermForVersion',
  async (payload, thunkApi) => {
    let url = `terms/${payload.data.version}`;
    return await apiCall({
      url,
      params: payload.params,
    }, thunkApi);
  }
);

export const updateTermInVersion = createAsyncThunk<null, ApiPayload, null>(
  'updateTermInVersion',
  async (payload, thunkApi) => await apiCall({
    method: 'put',
    url: `terms/${payload.data.version}/${payload.data.key}`,
    data: {
      items: payload.data.items,
      translations: payload.data.translations,
    }
  }, thunkApi)
);

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {

  },
  extraReducers: {
    // @ts-ignore
    [fetchTranslationPacks.fulfilled]: (state, action) => {
      if (action.payload) state.translationPacks = action.payload;
    },
    // @ts-ignore
    [fetchTranslationPack.fulfilled]: (state, action) => {
      if (action.payload) state.selectedTranslationPack = action.payload;
    },
    [fetchTermsForVersion.pending as any]: (state) => {
      state.termsList = [];
    },
    // @ts-ignore
    [fetchTermsForVersion.fulfilled]: (state, action) => {
      if (action.payload) state.termsList = action.payload;
    },
    [searchTranslationTermForVersion.pending as any]: (state) => {
      state.termsList = [];
    },
    [searchTranslationTermForVersion.fulfilled as any]: (state, action) => {
      if (action.payload) { 
        state.termsList = action.payload;
      }
    },
  }

});

export default translationsSlice.reducer
