import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { ITerm } from "../../common/types";

const TermCard = (props: { term: ITerm; body: any }) => (
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey={props.term.key}>
        {props.term.key}
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey={props.term.key}>
      <Card.Body>{props.body}</Card.Body>
    </Accordion.Collapse>
  </Card>
);

export default TermCard;
