import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const Translation = (props: {
    label: string;
    value: string;
    updateValue: any;
  }) => (
    <Form.Group as={Row}>
      <Form.Label column sm="2">
        {props.label}
      </Form.Label>
      <Col sm="10">
        <Form.Control
          defaultValue={props.value}
          onChange={(e) => {
            props.updateValue(props.label, e.target.value);
          }}
        />
      </Col>
    </Form.Group>
  );

export default Translation;