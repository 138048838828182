import React from 'react';
import './Breadcrumbs.scss';
import {Breadcrumb} from "react-bootstrap";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {Link} from "react-router-dom";

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div className="Breadcrumbs">
      <Breadcrumb>
        {breadcrumbs.map(({match,breadcrumb}, index) => (
          <Breadcrumb.Item
            key={match.url}
            linkAs={Link}
            linkProps={{to: match.url}}
            active={breadcrumbs.length - 1 === index}
          >
            {breadcrumb}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default Breadcrumbs;
