import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall, ApiPayload} from "../../helpers/apiCall";

interface VersionsState {
  versions: any,
  currentVersion: any
}

const initialState = {
  versions: [],
  currentVersion: {}
} as VersionsState;

export const fetchVersions = createAsyncThunk<null, ApiPayload, null>(
  'fetchVersions',
  async (payload, thunkAPI) => {
    return await apiCall({
      url: 'versions',
      ...payload
    }, thunkAPI);
  }
);

export const fetchVersionByName = createAsyncThunk<null, ApiPayload, null>(
  'fetchVersionByName',
  async (payload, thunkAPI) => {
    return await apiCall({
      url: 'versions/name/' + payload.data.name,
      ...payload
    }, thunkAPI);
  }
);



const versionsSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {

  },
  extraReducers: {
    // @ts-ignore
    [fetchVersions.fulfilled]: (state, action) => {
      if (action.payload) state.versions = action.payload;
    },
    // @ts-ignore
    [fetchVersionByName.fulfilled]: (state, action) => {
      if (action.payload) state.currentVersion = action.payload;
    },
  }

});

export default versionsSlice.reducer
