import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchImportTerms, mergeImportTerms } from "../ImportTerms/importTermsSlice";
import { createExportTerms } from '../ExportTerms/exportTermsSlice';
import { fetchTermsForVersion } from '../translationsSlice';
import { ListTerm, SimpleTerm } from "../../common/types";
import DiffViewer from "../../components/Terms/DiffViewer";
import { useParams } from "react-router-dom";

interface DispatchProps {
  createExportTerms;
  fetchImportTerms;
  fetchTermsForVersion;
  mergeImportTerms;
}

interface OwnProps {}

interface StateProps {
  languages: any[];
}

interface Params {
    importId: string;
}

type ImportTermsProps = DispatchProps & OwnProps & StateProps;
type Term =  SimpleTerm & ListTerm

const CompareImportTerms = (props: ImportTermsProps) => {
  const { createExportTerms, languages, fetchImportTerms, mergeImportTerms } =  props;
  const { importId } = useParams<Params>();
  const [termsKeysToCompare, setTermsKeysToCompare] = useState([]);
  const [alert, setAlert] = useState({ show: false, text: '', variant: ''});
  const [termsInTestingVersion, setTermsInTestingVersion] = useState<Term[]>([]);
  const [termsImported, setTermsImported] = useState<Term[]>([]);
  const [isReadyToCompare, setIsReadyToCompare] = useState(true);

  useEffect(() => {
    fetchImportTerms({
        data: {
            importId
        }
    }).then(response => {
        if (response?.payload) {
            const terms = response.payload.terms;
            setTermsImported(terms);
            setTermsKeysToCompare( terms.map( term => term.key ) );
        }
    });
  }, [importId]);

  useEffect(() => {
    if (termsKeysToCompare.length > 0) {
      const languageKeys = languages.map(({key}) => key);
      createExportTerms({
        data: {
          version: 'testing',
          keys: termsKeysToCompare,
          languages: languageKeys,
        }
      }).then((response) => {
        if (response?.payload) {
          const termsInTesting = response.payload;
          setAlert({ show: true, variant: 'success', text: `Obtained ${termsInTesting.length} Translation Terms from Testing Version`});
          setTermsInTestingVersion(termsInTesting);
          setIsReadyToCompare(true);
        }
      }).catch(() => {
        setAlert({ show: true, variant: 'danger', text: 'Cannot obtain Translation Terms from Testing version'})
      });
    }
  }, [termsKeysToCompare, languages]);

  return (
    <Container>
      <Row>
        <Col>
          <h3>Import Terms into Testing version</h3>
        </Col>
      </Row>
      <Row style={{ margin: '1rem 0' }}>
        <Col sm={10}></Col>
        <Col>
          <Button onClick={() => {
            setAlert({ show: true, variant: 'info', text: 'Importing content...' });
            mergeImportTerms({
              data: {
                importId,
              }
            }).then(response => {
              if (response?.payload) {
                console.log("Response", response.payload);
                setAlert({ show: true, variant: 'info', text: `Imported ${response.payload} Terms into Testing.` });
              } else {
                setAlert({ show: true, variant: 'danger', text: 'Cannot import Translation Terms into Testing version'});
              }
            }).catch(() => {
              setAlert({ show: true, variant: 'danger', text: 'Cannot import Translation Terms into Testing version'});
            });
          }}>Import Terms</Button>
        </Col>
      </Row>
      <Row>
          <Col>
          { alert.show && <Alert variant={alert.variant}>{alert.text}</Alert> }
          </Col>
      </Row>
      { isReadyToCompare && termsImported.map(term => {
        const termInTesting = termsInTestingVersion.find(termTesting => termTesting.key === term.key);
        if (!termInTesting) {
          return (<Row>
            <Col>
            <span>Cannot find {term.key} in Testing Version. Ignoring</span>
            </Col>
          </Row>);
        } else {
          return <DiffViewer oldTerm={termInTesting} newTerm={term} />
        }
      }) }
    </Container>
  );
};

const mapDispatchToProps: DispatchProps = {
  createExportTerms,
  fetchImportTerms,
  fetchTermsForVersion,
  mergeImportTerms,
};

const mapStateToProps = (state: any): StateProps => ({
  languages: state.languages,
});

const Wrapped = connect(mapStateToProps, mapDispatchToProps)(CompareImportTerms);

export default Wrapped;
