import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall, ApiPayload} from "../../helpers/apiCall";

interface TranslationsState {
  applications: any,
  currentApplication: any
}

const initialState = {
  applications: [],
  currentApplication: {}
} as TranslationsState

export const fetchApplications = createAsyncThunk(
  'fetchApplications',
  async (data, thunkAPI) => {
    return await apiCall({
      url: 'applications'
    }, thunkAPI);
  }
);

export const createApplication = createAsyncThunk<null, ApiPayload, null>(
  'createApplication',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'applications',
      ...payload,
    }, thunkAPI);
  }
);

export const fetchApplication = createAsyncThunk<null, ApiPayload, null>(
  'fetchApplication',
  async (payload, thunkAPI) => {
    return await apiCall({
      url: 'applications/key/' + payload.data.name,
      ...payload,
    }, thunkAPI);
  }
);

export const addTranslationPack = createAsyncThunk<null, ApiPayload, null>(
  'addTranslationPack',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'put',
      url: `applications/${payload.data.id}/translationPacks/${payload.data.translationPackId}`,
      ...payload,
    }, thunkAPI);
  }
);

export const removeTranslationPack = createAsyncThunk<null, ApiPayload, null>(
  'removeTranslationPack',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'delete',
      url: `applications/${payload.data.id}/translationPacks/${payload.data.translationPackId}`,
      ...payload,
    }, thunkAPI);
  }
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {

  },
  extraReducers: {
    // @ts-ignore
    [fetchApplications.fulfilled]: (state, action) => {
      if (action.payload) state.applications = action.payload;
    },
    // @ts-ignore
    [fetchApplication.fulfilled]: (state, action) => {
      if (action.payload) state.currentApplication = action.payload;
    },
  }

});

export default applicationsSlice.reducer
