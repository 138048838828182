import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface OwnProps {
    show: boolean;
    onHideModal: any;
    createUser: any;
}

const CreateUserModal = (props: OwnProps) => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onCreateUserSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          return ;
        }
        setValidated(true);
        props.createUser(email, password);
      };

    return (
<Modal show={props.show} onHide={props.onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title> Create Administrator User </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={onCreateUserSubmit}>
            <Form.Group>
              <Form.Label>All fields are required</Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>E-mail address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="User e-mail address"
                onKeyUp={(e) => { setEmail(e.target.value); }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="User password"
                onKeyUp={(e) => { setPassword(e.target.value); }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Button type="submit" variant="success">
                Create
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );
};

export default CreateUserModal;