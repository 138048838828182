import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router";
import ExportTermsBasket from "../../components/Terms/ExportTermsBasket";
import {
  createTerm,
  fetchTermsForVersion,
  updateTermInVersion,
} from "../translationsSlice";
import SimpleTermCard from "../../components/Terms/SimpleTerm";
import ListTermCard from "../../components/Terms/ListTerm";
import AddTermDropdown from "../../components/Terms/AddTermDropdown";
import CreateTermModal from "../../components/Terms/CreateTermModal";
import CreateTermListModal from "../../components/Terms/CreateTermListModal";
import { ListTerm, SimpleTerm } from "../../common/types";
interface DispatchProps {
  fetchTermsForPackInVersion: any;
  updateTermInVersion: any;
  createTerm: any;
}

interface StateProps {
  termsList: any;
  languages: any[];
  termsToExport: any[];
}

interface OwnProps {
  history: any;
}

type TranslationTermsProps = DispatchProps & StateProps & OwnProps;

interface PageParams {
  version: string;
  translationPack: string;
}

const TranslationTerms = (props: TranslationTermsProps) => {
  const { version, translationPack } = useParams<PageParams>();
  const {
    fetchTermsForPackInVersion,
    termsList,
    languages,
    updateTermInVersion,
    termsToExport,
    history,
    createTerm,
  } = props;
  const [termsToDisplay, setTermsToDisplay] = useState(termsList);
  const [filterText, setFilterText] = useState("");
  const [showCreateTermModal, setShowCreateTermModal] = useState(false);
  const [showCreateTermListModal, setShowCreateTermListModal] = useState(false);
  const [showCreateTermAsValueModal, setShowCreateTermAsValueModal] = useState(false);
  const [alertSetting, setAlertSettings] = useState({
    show: false,
    text: "",
    variant: "success",
  });
  const [refreshTerms, setRefreshTerms] = useState(false);

  const languagesKeys = languages.map(({ key }) => key);

  const updateTranslationsTerm = (key, translations) =>
    updateTermInVersion({
      data: {
        version,
        key,
        translations,
      },
    });
  const updateItemsTerm = (key, items) =>
    updateTermInVersion({
      data: {
        version,
        key,
        items,
      },
    });

  useEffect(() => {
    fetchTermsForPackInVersion({
      data: {
        versionName: version,
        packName: translationPack,
      },
      params: {
        limit: 3000,
      },
    });
  }, [version, translationPack, fetchTermsForPackInVersion]);

  useEffect(() => {
    if (refreshTerms) {
      fetchTermsForPackInVersion({
        data: {
          versionName: version,
          packName: translationPack,
        },
        params: {
          limit: 3000,
        },
      });

      setRefreshTerms(false);
    }
  }, [refreshTerms]);

  useEffect(() => {
    if (filterText !== "") {
      setTermsToDisplay(
        termsToDisplay.filter((term) => term.key.includes(filterText))
      );
    } else {
      setTermsToDisplay(termsList);
    }
  }, [filterText, termsList]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={9}>
            <h3 style={{ textTransform: 'capitalize' }}>Translation Terms: {translationPack}</h3>
          </Col>
          <Col>
            <AddTermDropdown
              items={[
                {
                  text: "Term",
                  action: () => {
                    setShowCreateTermModal(true);
                  },
                },
                {
                  text: "Term as List",
                  action: () => {
                    setShowCreateTermListModal(true);
                  },
                },
                {
                  text: "Term as Value",
                  action: () => {
                    setShowCreateTermAsValueModal(true);
                  },
                },
              ]}
            />
          </Col>
          <Col>
            {version === "testing" && (
              <ExportTermsBasket version={version} history={history} />
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <InputGroup>
              <FormControl
                placeholder="Filter Translation Terms"
                aria-label="Filter Translation Terms"
                onKeyUp={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {alertSetting.show && (
              <Alert variant={alertSetting.variant}>{alertSetting.text}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              {termsToDisplay.map((term) => {
                if (Array.isArray(term.items) && term.items.length > 0) {
                  return (
                    <ListTermCard
                      term={term as ListTerm}
                      languages={languagesKeys}
                      updateTerm={updateItemsTerm}
                      key={term._id || `list-${term.key}`}
                      version={version}
                      termsToExport={termsToExport}
                    />
                  );
                } else if (term.translations.type === 'value' || term.translations.value) {
                  return (
                    <SimpleTermCard
                      term={term as SimpleTerm}
                      languages={['label', 'value']}
                      key={term._id || `simple-${term.key}`}
                      updateTerm={updateTranslationsTerm}
                      version={version}
                      termsToExport={termsToExport}
                    />
                  );  
                }
                return (
                  <SimpleTermCard
                    term={term as SimpleTerm}
                    languages={languagesKeys}
                    key={term._id || `simple-${term.key}`}
                    updateTerm={updateTranslationsTerm}
                    version={version}
                    termsToExport={termsToExport}
                  />
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
      <CreateTermModal
        show={showCreateTermModal}
        handleClose={() => {
          setShowCreateTermModal(false);
        }}
        handleSave={() => {
          setShowCreateTermModal(false);
        }}
        languages={languagesKeys}
        packName={translationPack}
        handleSubmit={(key, terms) => {
          createTerm({
            data: {
              key,
              version,
              pack: translationPack,
              translations: terms,
            },
          }).then((response) => {
            setShowCreateTermModal(false);
            if ((response.payload as string).toLowerCase() === "created") {
              setAlertSettings({
                show: true,
                text: `Term ${key} successfully created.`,
                variant: "success",
              });
              setRefreshTerms(true);
            } else {
              setAlertSettings({
                show: true,
                text: `An error has occurred while creating Term ${key}.`,
                variant: "danger",
              });
            }
          });
        }}
      />
      <CreateTermModal
        show={showCreateTermAsValueModal}
        handleClose={() => {
          setShowCreateTermAsValueModal(false);
        }}
        handleSave={() => {
          setShowCreateTermAsValueModal(false);
        }}
        languages={['label', 'value']}
        packName={translationPack}
        handleSubmit={(key, terms) => {
          createTerm({
            data: {
              key,
              version,
              pack: translationPack,
              translations: { ...terms, type: 'value' },
            },
          }).then((response) => {
            setShowCreateTermAsValueModal(false);
            if ((response.payload as string).toLowerCase() === "created") {
              setAlertSettings({
                show: true,
                text: `Term ${key} successfully created.`,
                variant: "success",
              });
              setRefreshTerms(true);
            } else {
              setAlertSettings({
                show: true,
                text: `An error has occurred while creating Term ${key}.`,
                variant: "danger",
              });
            }
          });
        }}
      />
      <CreateTermListModal
        show={showCreateTermListModal}
        handleClose={() => {
          setShowCreateTermListModal(false);
        }}
        handleSave={() => {
          setShowCreateTermListModal(false);
        }}
        languages={languagesKeys}
        packName={translationPack}
        handleSubmit={(key, items) => {
          createTerm({
            data: {
              key,
              version,
              pack: translationPack,
              items,
            },
          }).then((response) => {
            setShowCreateTermListModal(false);
            if ((response.payload as string).toLowerCase() === "created") {
              setAlertSettings({
                show: true,
                text: `Term ${key} successfully created.`,
                variant: "success",
              });
              setRefreshTerms(true);
            } else {
              setAlertSettings({
                show: true,
                text: `An error has occurred while creating Term ${key}.`,
                variant: "danger",
              });
            }
          });
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  termsList: state.translations.termsList,
  languages: state.languages,
  termsToExport: state.exportTerms.termsToExport,
});
const mapDispatchToProps: DispatchProps = {
  fetchTermsForPackInVersion: fetchTermsForVersion,
  updateTermInVersion,
  createTerm,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(TranslationTerms);

export default Wrapped;
