import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCall, ApiPayload } from '../../helpers/apiCall';

const initialState = {
    termsToExport: [],
    packsToExport: [],
};

export const createExportTerms = createAsyncThunk<null, ApiPayload, null>(
    'createExportTerms',
    async (payload, thunkApi) => {
        const packIds = payload.data?.packs?.map(pack => pack.id);

        return await apiCall({
            method: 'post',
            url: `terms/export/${payload.data.version}`,
            data: {
                keys: payload.data.keys,
                languages: payload.data.languages,
                packs: packIds,
            },
        }, thunkApi);
    }
);

const exportTermsSlice = createSlice({
    name: 'exportTerms',
    initialState,
    reducers: {
        addTerm: (state, action) => {
            if (!state.termsToExport.find(({key}) => action.payload.key === key)) {
                state.termsToExport.push(action.payload);
            }
        },
        removeTerm: (state, action) => {
            state.termsToExport = state.termsToExport.filter(term => term.key !== action.payload);
        },
        clearTerms: (state) => {
            state.termsToExport = [];
        },
        addPack: (state, action) => {
            if (action.payload.id && !state.packsToExport.find(pack => action.payload.id === pack.id)) {
                state.packsToExport.push(action.payload);
            }
        },
        removePack: (state, action) => {
            state.packsToExport = state.packsToExport.filter(pack => pack.id !== action.payload);
        },
        clearPacks: (state) => {
            state.packsToExport = [];
        }
    }
});

export const { addTerm, clearTerms, removeTerm, addPack, clearPacks, removePack } = exportTermsSlice.actions;

export const selectedItemsCount = state => state.exportTerms.termsToExport.length + state.exportTerms.packsToExport.length;

export default exportTermsSlice.reducer;