import React, {useEffect, useState} from 'react';
import './Releases.scss';
import {Button, Card, Form} from "react-bootstrap";
import { connect } from 'react-redux';
import {fetchVersions} from "./../Versions/versionsSlice";
import {promoteTesting, release} from "./releasesSlice";


interface StateProps {
  versions: any,
}

interface DispatchProps {
  fetchVersions: any,
  promoteTesting: any,
  release: any
}

interface OwnProps {
}

function Releases({ versions, fetchVersions, promoteTesting, release }) {
  useEffect(() => {
    fetchVersions();
  }, []);
  return (
    <div className={"Releases"}>
      <h3>Releases</h3>
      <div className="content">
        <Button variant="primary" size="lg"
          onClick={() => promoteTesting()}
        >
          Promote testing to production
        </Button>
        <h3>Deploy release</h3>
        {versions.map(version => (
          <Button variant="primary" size="lg"
            onClick={() => release({
              data: { version: version.name }
            })}
          >
            Release {version.name}
          </Button>
          )
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  versions: state.versions.versions,
});

const mapDispatchToProps = {
  fetchVersions: fetchVersions,
  promoteTesting: promoteTesting,
  release: release
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Releases);

export default Wrapped;
