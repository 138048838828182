import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import modalReducer from '../components/Modal/modalSlice';
import translationsReducer from './translationsSlice';
import loaderReducer from "../components/Loader/loaderState";
import userReducer from './userSlice';
import languagesReducer from "../languagesSlice";
import applicationsReducer from './Applications/applicationsSlice';
import versionsReducer from './Versions/versionsSlice';
import exportTermsReducer from './ExportTerms/exportTermsSlice';
import importTermsReducer from './ImportTerms/importTermsSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
    counter: counterReducer,
    exportTerms: exportTermsReducer,
    importTerms: importTermsReducer,
    modal: modalReducer,
    translations: translationsReducer,
    loader: loaderReducer,
    user: userReducer,
    languages: languagesReducer,
    applications: applicationsReducer,
    versions: versionsReducer
});

const persistConfig = { 
  key: 'root',
  storage,
  whitelist: ['exportTerms'],
};

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
