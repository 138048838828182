import React, {ReactComponentElement} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Modal.scss';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch, connect } from 'react-redux';
import {selectModalData, hide} from "./modalSlice";

export interface StateProps {
  modalData: any
}

export interface DispatchProps {
  hideModal: any
}

export interface OwnProps {
  children?: any,
  onClose?: any,
  displayModal?: boolean,
  title?: string,
  onSave?: any,
  customButtons?: any,
}

export type ModalProps = StateProps & DispatchProps & OwnProps;


function Modal({ modalData, hideModal, children, onClose, displayModal, title, onSave, customButtons }: ModalProps) {
  const modal = modalData[0] || {};
  const handleClose = () => {
    if (children) onClose();
    else hideModal(modal.id)
  };

  const display = modalData.length > 0 || (children && displayModal);
  return (
    <div className="Modal">
      <BootstrapModal show={display} onHide={handleClose}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>{title || ""}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {children}
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          {customButtons ||
            <>
              <Button variant="secondary" onClick={handleClose}>
                  Close
              </Button>
              <Button variant="primary" onClick={() =>{
                onSave();
                handleClose();
              }}>
                  Save Changes
              </Button>
            </>
          }
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  modalData: state.modal.modalData,
});

const mapDispatchToProps = {
  hideModal: hide,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);

export default Wrapped;
