import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addPack, removePack } from '../ExportTerms/exportTermsSlice';

interface OwnProps {
    pack: any;
    hasBeenAdded: boolean;
}

const ToggleExportPack = (props: OwnProps) => {
    const { hasBeenAdded, pack } = props;
    const [toBeAdded, setToBeAdded] = useState(!hasBeenAdded);
    const dispatch = useDispatch();

    return (
        toBeAdded ? 
        <Button variant='outline-info' block onClick={() => { dispatch(addPack(pack)); setToBeAdded(false); }}>Add to Export</Button> 
        :
        <Button variant='outline-warning' block onClick={() => { dispatch(removePack(pack.id)); setToBeAdded(true); }}>Remove from Export</Button>
    );
};

export default ToggleExportPack;