import React, { useEffect } from "react";
import "./TranslationPacksSelectForVersion.scss";
import {
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Nav,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchTranslationPacks,
  searchTranslationTermForVersion,
} from "../translationsSlice";
import { useParams } from "react-router-dom";
import ExportTermsBasket from "../../components/Terms/ExportTermsBasket";
import SearchTerms from "../../components/Terms/SearchTerms";
import ToggleExportPack from "./ToggleExportTranslationPack";

interface StateProps {
  translationPacksData: any;
  packsToExport: any[];
}

interface DispatchProps {
  fetchTranslationPacks: any;
  searchTranslationTermForVersion: any;
}

interface OwnProps {
  history: any;
}

export type TranslationPacksSelectForVersionProps = StateProps &
  DispatchProps &
  OwnProps;

function TranslationPacksSelectForVersion({
  fetchTranslationPacks,
  translationPacksData,
  history,
  packsToExport,
}: TranslationPacksSelectForVersionProps) {
  const { version } = useParams();

  useEffect(() => {
    fetchTranslationPacks();
  }, []);

  const handleSearchTerms = (text) => {
    history.push(`/search/${version}?q=${text}`);
  };

  return (
    <Container fluid className={"TranslationPacksSelectForVersion"}>
      {version === "testing" && (
        <Row>
          <Col md={2}>
            <Button
              onClick={() => {
                history.push("/import-terms/");
              }}
            >
              Import Terms
            </Button>
          </Col>
          <Col>
            <ExportTermsBasket history={history} version={version} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h3>
            Translation packs in{" "}
            <span style={{ textTransform: "capitalize" }}>{version}</span>
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center" style={{ margin: "2rem 0" }}>
        <Col xs lg="10">
          <SearchTerms searchTerms={handleSearchTerms} />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs lg="10">
          <CardGroup>
            {translationPacksData.map((translationPack) => (
              <Card key={translationPack._id}>
                <Card.Body>
                  <Card.Title
                    onClick={() =>
                      history.push(
                        `/versions/${version}/${translationPack.key}`
                      )
                    }
                  >
                    <h4 className="text-capitalize">{translationPack.key}</h4>
                  </Card.Title>
                  {version === "testing" && (
                    <Card.Text>
                      <ToggleExportPack
                        pack={{
                          id: translationPack._id,
                          name: translationPack.key,
                        }}
                        hasBeenAdded={
                          !!packsToExport.find(
                            (pack) => pack.id === translationPack._id
                          )
                        }
                      />
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            ))}
          </CardGroup>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  translationPacksData: state.translations.translationPacks,
  packsToExport: state.exportTerms.packsToExport,
});

const mapDispatchToProps = {
  fetchTranslationPacks: fetchTranslationPacks,
  searchTranslationTermForVersion,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(TranslationPacksSelectForVersion);

export default Wrapped;
