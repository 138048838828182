import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { ITerm, ITranslation, ListTerm } from "../../common/types";
import TermCard from "./TermsCard";
import ToggleExportTerm from "./ToggleExportTerm";
import Translation from "./Translation";

const ListTermCard = (props: {
  term: ListTerm;
  languages: string[];
  updateTerm: any;
  version: string;
  termsToExport: any[];
}) => {
  const validProps = ["label", ...props.languages, "position"];
  const emptyValues = props.languages.reduce(
    (acc, key) => ({ ...acc, [key]: "" }),
    {}
  );
  const [term, setTerm] = useState(props.term);
  const [showSpinner, setShowSpinner] = useState(false);

  const updateTermItem = (index) => (key, value) => {
    const itemsToReplace = [...term.items];
    const currentItems = { ...term.items[index] };
    itemsToReplace.splice(index, 1, { ...currentItems, [key]: value });
    setTerm({ ...term, items: itemsToReplace });
  };

  return (
    <TermCard
      term={term}
      body={
        <>
          {term.items.map((item, index) => {
            const itemEntries = { ...emptyValues, ...item };
            return (
              <>
                <Form.Label>{itemEntries.label}</Form.Label>
                {Object.entries(itemEntries)
                  .filter(([key, _]) => validProps.includes(key))
                  .map(([key, value]) => (
                    <Translation
                      label={key}
                      value={value}
                      key={key}
                      updateValue={updateTermItem(index)}
                    />
                  ))}
              </>
            );
          })}
          <Container fluid>
            <Row style={{ display: "flex", justifyContent: "end" }}>
              <Col md={8}></Col>
              <Col md={2}>
                {props.version === "testing" && (
                  <ToggleExportTerm
                    hasBeenAdded={
                      !!props.termsToExport.find(
                        ({ key }) => props.term.key === key
                      )
                    }
                    term={props.term}
                  />
                )}
              </Col>
              <Col md={2}>
                <Button
                  variant="success"
                  block
                  onClick={() => {
                    setShowSpinner(true);
                    props
                      .updateTerm(term.key, term.items)
                      .then(() => {
                        setShowSpinner(false);
                      })
                      .catch(() => {
                        setShowSpinner(false);
                      });
                  }}
                  disabled={showSpinner}
                >
                  {showSpinner ? (
                    <Spinner animation="border" variant="success" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      }
    />
  );
};

export default ListTermCard;
