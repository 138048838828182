import React, {useEffect, useState} from 'react';
import './TranslationPacksSelect.scss';
import {Button, Card, Form} from "react-bootstrap";
import {connect} from "react-redux";
import {createTranslationPack, fetchTranslationPacks} from '../translationsSlice'
import Modal from "../../components/Modal/Modal";

interface StateProps {
  translationPacksData: any
}

interface DispatchProps {
  fetchTranslationPacks: any
}

interface OwnProps {
}

function TranslationPacksSelect({ fetchTranslationPacks, translationPacksData, createTranslationPack, history }) {
  useEffect(() => {
    fetchTranslationPacks();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [translationPackInputValid, setTranslationPackInputValid] = useState(true);
  const [newTranslationPackInput, setNewTranslationPackInput] = useState('');

  const translationPackInput = (
    <Form>
      <Form.Group controlId="formTranslationCreate">
        <Form.Label>New translation pack</Form.Label>
        <div className="translationInputFieldWrapper">
          <Form.Control
            type="text"
            placeholder="Enter name here"
            onChange={(event) => {
              const match = event.target.value.match('^([A-Za-z0-9_])+$')
              setTranslationPackInputValid(!!match);
              if (match) setNewTranslationPackInput(event.target.value);
            }}
            isInvalid={!translationPackInputValid}
          />
        </div>
      </Form.Group>
    </Form>
  );


  return (
    <div className={"TranslationPacksSelect"}>
      <h3>Translation packs</h3>
      <Modal
        title={"Add translation pack"}
        onClose={() => {
          setTranslationPackInputValid(true);
          setNewTranslationPackInput("");
          setShowModal(false);
        }}
        displayModal={showModal}
        onSave={() => {
          if (translationPackInputValid) {
            createTranslationPack({
              data: {
                key: newTranslationPackInput,
              },
              callback: () => {
                fetchTranslationPacks();
              }
            })
          }
        }}
      >
        {translationPackInput}
      </Modal>
      <Button className="createPack" variant="primary" size="lg"
              onClick={() => setShowModal(!showModal)}
      >
        Create new translation pack
      </Button>
      <div className="cards">
        {translationPacksData.map(translationPack => (
            <Card key={translationPack._id} onClick={() => history.push('translations/' + translationPack.key)}>
              <Card.Title>
                {translationPack.key}
              </Card.Title>
            </Card>
          )
        )}
      </div>

    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  translationPacksData: state.translations.translationPacks,
});

const mapDispatchToProps = {
  fetchTranslationPacks: fetchTranslationPacks,
  createTranslationPack: createTranslationPack,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TranslationPacksSelect);

export default Wrapped;
