import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import "./addterm.scss";

interface FormProps {
  defaultLanguage: string;
  languages: string[];
  packName: string;
  handleSubmit: any;
}

const AddForm = (props: FormProps) => {
  const [validated, setValidated] = useState(false);
  const [key, setKey] = useState("");
  const validProps = ["label", ...props.languages, "position"];
  const requiredProps = [props.defaultLanguage, "label"];
  const termsObject = validProps.reduce(
    (acc, current) => ({ ...acc, [current]: "" }),
    {}
  );

  const [termItems, setTermItems] = useState<object[]>([]);

  const updateTermItem = (index) => (key, value) => {
    const itemsToReplace = [...termItems];
    const currentItems = { ...termItems[index] };
    itemsToReplace.splice(index, 1, { ...currentItems, [key]: value });
    setTermItems(itemsToReplace);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return ;
    }
    props.handleSubmit(`${props.packName}.${key}`, termItems);
    setValidated(true);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Term Path
        </Form.Label>
        <Col sm={10}>
          <InputGroup hasValidation>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-sm">
                {props.packName}.
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="translation.term"
              required={true}
              onKeyUp={(e) => {
                setKey(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please specify the path.
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Text className="text-muted">
            Unique identifier for this term. Only letters, dot and underscore
            are allowed.
          </Form.Text>
        </Col>
      </Form.Group>
      {termItems.map((item, index) => (
        <div key={`item-${index}`}>
          <h4>Item {index + 1}</h4>

          {Object.keys(item).map((property) => {
            const isRequired = requiredProps.includes(property);
            return (
              <Form.Group as={Row} key={`prop-${index}-${property}`}>
                <Form.Label
                  column
                  sm={2}
                  className={isRequired ? "required" : ""}
                >
                  {property}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    required={isRequired}
                    onKeyUp={(e) => {
                      updateTermItem(index)(property, e.target.value);
                    }}
                    defaultValue={item[property]}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please specify the translation.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            );
          })}
        </div>
      ))}
      <Form.Group as={Row}>
        <Col>
          <Button block onClick={() => {
            const itemsToAdd = [...termItems];
            itemsToAdd.push({ ...termsObject, position: termItems.length + 1 });
            setTermItems(itemsToAdd);
            }}>Add Item to List</Button>
        </Col>
      </Form.Group>
      <Button type='submit' variant="success" block>
        Save
      </Button>
    </Form>
  );
};

const CreateTermListModal = (props: {
  show: boolean;
  handleClose: any;
  handleSave: any;
  languages: string[];
  packName: string;
  handleSubmit: any;
  defaultLanguage?: string;
}) => (
  <Modal show={props.show} onHide={props.handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Create Term as List</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddForm
        defaultLanguage={props.defaultLanguage || "en"}
        languages={props.languages}
        packName={props.packName}
        handleSubmit={props.handleSubmit}
      />
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
);

export default CreateTermListModal;
