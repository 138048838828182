import React, {useEffect, useState} from 'react';
import './Versions.scss';
import {Button, Card, Form} from "react-bootstrap";
import { connect } from 'react-redux';
import {fetchVersions} from "./versionsSlice";


interface StateProps {
  versions: any,
}

interface DispatchProps {
  fetchVersions: any,
}

interface OwnProps {
}

function Versions({ versions, fetchVersions, history }) {
  useEffect(() => {
    fetchVersions();
  }, []);
  return (
    <div className={"Versions"}>
      <h3>Translation Terms - Versions</h3>
      <div className="cards">
        {versions.map(version => (
            <Card
              key={version.name}
              onClick={() => history.push(`/versions/${version.name}`)}>
              <Card.Title>
                {version.name}
              </Card.Title>
            </Card>
          )
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  versions: state.versions.versions,
});

const mapDispatchToProps = {
  fetchVersions: fetchVersions,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Versions);

export default Wrapped;
