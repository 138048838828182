import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addTerm, removeTerm } from '../../app/ExportTerms/exportTermsSlice';

interface OwnProps {
    term: any;
    hasBeenAdded: boolean;
}

const ToggleExportTerm = (props: OwnProps) => {
    const { hasBeenAdded, term } = props;
    const [toBeAdded, setToBeAdded] = useState(!hasBeenAdded);
    const dispatch = useDispatch();

    return (
        toBeAdded ? 
        <Button variant='info' onClick={() => { dispatch(addTerm(term)); setToBeAdded(false); }} block> Add to Export </Button> 
        :
        <Button variant='warning' onClick={() => { dispatch(removeTerm(term)); setToBeAdded(true); }} block> Remove from Export</Button>
    );
};

export default ToggleExportTerm;