import React, { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { SimpleTerm } from "../../common/types";
import TermCard from "./TermsCard";
import ToggleExportTerm from "./ToggleExportTerm";
import Translation from "./Translation";

const SimpleTermCard = (props: {
  term: SimpleTerm;
  languages: string[];
  updateTerm: any;
  version: string;
  termsToExport: any[];
}) => {
  const emptyValues = props.languages.reduce((acc, key) => ({...acc, [key]: ""}), {});
  const translations = { ...emptyValues, ...props.term.translations };
  const [term, setTerm] = useState({ ...props.term, translations });
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <TermCard
      term={term}
      body={
        <>
          {Object.entries(term.translations)
            .filter(([key, _]) => props.languages.includes(key))
            .map(([key, value]) => (
              <Translation
                label={key}
                value={value}
                key={key}
                updateValue={(key, value) => {
                  setTerm({
                    ...term,
                    translations: { ...term.translations, [key]: value },
                  });
                }}
              />
            ))}
          <Container fluid>
            <Row style={{ display: "flex", justifyContent: "end" }}>
              <Col md={8}></Col>
              <Col md={2}>
                {props.version === "testing" && (
                  <ToggleExportTerm
                    hasBeenAdded={
                      !!props.termsToExport.find(
                        ({ key }) => props.term.key === key
                      )
                    }
                    term={props.term}
                  />
                )}
              </Col>
              <Col md={2}>
                <Button
                  variant="success"
                  block
                  onClick={() => {
                    setShowSpinner(true);
                    props
                      .updateTerm(term.key, term.translations)
                      .then(() => {
                        setShowSpinner(false);
                      })
                      .catch(() => {
                        setShowSpinner(false);
                      });
                  }}
                  disabled={showSpinner}
                >
                  {showSpinner ? (
                    <Spinner animation="border" variant="success" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      }
    />
  );
};

export default SimpleTermCard;
