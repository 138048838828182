import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiCall } from "../helpers/apiCall";

interface UserState {
  user: any,
  allUsers: any[],
}

interface ApiPayload {
  data: any,
  callback: any,
  callbackError: any,
};

const initialState = {
  user: {},
} as UserState

export const checkUser = createAsyncThunk<null, ApiPayload, null>(
  'checkUser',
  async (payload, thunkAPI) => {
    return await apiCall({
      url: 'auth/check',
      ...payload
    }, thunkAPI);
  }
);

export const createUser = createAsyncThunk<null, ApiPayload, null>(
  'createUser',
  async (payload, thunkApi) => {
    return await apiCall({ 
      url: 'user',
      method: 'post', 
      data: payload.data 
    }, thunkApi)
  }
);

export const listUsers = createAsyncThunk<null, ApiPayload, null>(
  'listUsers',
  async (payload, thunkApi) => {
    return await apiCall({ url: 'user' }, thunkApi);
  }
);

export const login = createAsyncThunk<null, ApiPayload, null>(
  'login',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'auth/',
      ...payload
    }, thunkAPI);
  }
);

export const logout = createAsyncThunk<null, ApiPayload, null>(
  'logout',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'auth/logout',
      ...payload
    }, thunkAPI);
  }
);

const userSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {

  },
  extraReducers: {
    // @ts-ignore
    [login.fulfilled]: (state, action) => {
      if (action.payload) state.user = action.payload;
    },
    // @ts-ignore
    [checkUser.fulfilled]: (state, action) => {
      if (action.payload) state.user = action.payload;
    },
    [listUsers.fulfilled as any]: (state, action) => {
      if (action.payload) {
        state.allUsers = action.payload;
      }
    },
    // @ts-ignore
    [logout.fulfilled]: (state, action) => {
      state.user = {};
    }
  }

});

export default userSlice.reducer
