import React, {useEffect, useState} from 'react';
import './Applications.scss';
import { Card, Button, Form } from "react-bootstrap";
import Modal from "../../components/Modal/Modal";
import {connect} from "react-redux";
import {createApplication, fetchApplications} from "./applicationsSlice";
import {useParams} from "react-router-dom";

interface StateProps {
  applications: any,
}

interface DispatchProps {
  fetchApplications: any,
  createApplication: any,
}

interface OwnProps {
  history: any,
}

function Applications({ applications, fetchApplications, createApplication, history }) {
  useEffect(() => {
    fetchApplications();
  }, []);

  const [displayModal, setDisplayModal] = useState(false);
  const [newApplicationName, setNewApplicationName] = useState("");
  const applicationInput = (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Application name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter here"
          onChange={(event) => {
            setNewApplicationName(event.target.value)
          }}
        />
      </Form.Group>
    </Form>
  );

  return (
    <div className={"Applications"}>
      <Modal
        title={"Create Application"}
        onClose={() => {
          setNewApplicationName("")
          setDisplayModal(false)
        }}
        displayModal={displayModal}
        onSave={() => {
          createApplication({
            data: {
              key: newApplicationName,
            },
            callback: () => {
              fetchApplications();
            }
          });
        }}
      >
        {applicationInput}
      </Modal>
      <Button variant="primary" onClick={() => setDisplayModal(true)}>Create</Button>
      <div className="cards">
        {applications.map(app => (
            <Card onClick={() => history.push(`/applications/${app.key}`)}>
              <Card.Title>
                {app.key}
              </Card.Title>
            </Card>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  applications: state.applications.applications
});

const mapDispatchToProps = {
  fetchApplications: fetchApplications,
  createApplication: createApplication,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Applications);

export default Wrapped;
