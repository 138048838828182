import React from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";

interface DispatchProps {}

interface StateProps {
  itemsCount: number;
}

interface OwnProps {
  history: any;
  version: string;
}

export type ExportTermsBasketProps = DispatchProps & StateProps & OwnProps;

const ExportTermsBasket = (props: ExportTermsBasketProps) => {
  const { history, itemsCount, version } = props;
  return (
    version === "testing" && (
      <Container fluid>
        <Row>
          <Col lg={{ order: "last" }}>
            <Button
              className="float-right"
              variant="primary"
              onClick={() => history.push(`/export-terms/${version}`)}
            >
              Export: {itemsCount}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  );
};

const mapStateToProps = (state: any): StateProps => ({
  itemsCount:
    state.exportTerms.termsToExport.length +
    state.exportTerms.packsToExport.length,
});

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(
  ExportTermsBasket
);

export default Wrapped;
