import React, {ReactComponentElement} from 'react';
import './Login.scss';
import { connect } from 'react-redux';
import {Button, Card, Form} from "react-bootstrap";
import {login} from "../../app/userSlice";

export interface StateProps {
}

export interface DispatchProps {
  login: any
}

export interface OwnProps {
  history: any
}

export type LoginProps = StateProps & DispatchProps & OwnProps;


function Login({ login, history }: LoginProps) {


  return (
    <div className="Login">
      <Card>
        <Card.Header>Login</Card.Header>
        <Card.Body>
          <Form onSubmit={e => {
            e.preventDefault();
            console.log(e)
            login({
              data: {
                email: e.currentTarget.email.value,
                password: e.currentTarget.password.value
              },
              callback: () => {
                history.push('/')
              },
              callbackError: () => {

              }
            })
          }}>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
});

const mapDispatchToProps = {
  login: login
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

export default Wrapped;
