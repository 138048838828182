import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiCall, ApiPayload } from '../../helpers/apiCall';

const initialState = {
    termsToImport: [],
};

export const createImportTerms = createAsyncThunk<null, ApiPayload, null>(
    'createImportTerms',
    async (payload, thunkApi) => {
        return await apiCall({
            method: 'post',
            url: `terms/import`,
            data: {
                name: payload.data.name,
                terms: payload.data.terms,
            },
        }, thunkApi);
    }
);

export const fetchImportTerms = createAsyncThunk<null, ApiPayload, null>(
    'fetchImportTerms',
    async (payload, thunkApi) => {
        return await apiCall({
            url: `terms/import/${payload.data.importId}`,
        }, thunkApi);
    }
);

export const mergeImportTerms = createAsyncThunk<null, ApiPayload, null>(
    'mergeImportTerms',
    async (payload, thunkApi) => {
        return await apiCall({
            url: `terms/import/${payload.data.importId}`,
            method: 'post',
        }, thunkApi)
    }
);

const importTermsSlice = createSlice({
    name: 'importTerms',
    initialState,
    reducers: {
    }
});

export default importTermsSlice.reducer;