import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import React, { useEffect, useState } from "react";
import { Nav, Navbar, Spinner, NavDropdown } from "react-bootstrap";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { checkUser, logout } from "./app/userSlice";
import _ from "lodash";
import { fetchLanguages } from "./languagesSlice";

import Applications from "./app/Applications/Applications";
import Application from "./app/Application/Application";
import TranslationPacksSelect from "./app/TranslationPacksSelect/TranslationPacksSelect";
import TranslationTerms from "./app/Translations/TranslationTerms";
import SearchTranslationTerms from './app/Translations/SearchTranslationTerms';
import Modal from "./components/Modal/Modal";
import Login from "./components/Login/Login";
import Versions from "./app/Versions/Versions";
import Releases from "./app/Releases/Releases";
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs";
import TranslationPacksSelectForVersion from "./app/TranslationPacksSelectForVersion/TranslationPacksSelectForVersion";
import ExportTerms from "./app/ExportTerms/ExportTerms";
import Users from "./app/Users/UserList";
import ImportTerms from './app/ImportTerms/ImportTerms';
import CompareImportTerms from './app/ImportTerms/CompareImportTerms';

interface StateProps {
  loaderShown: any;
  user: any;
  languages: any;
}

interface DispatchProps {
  checkUser: any;
  logout: any;
  fetchLanguages: any;
}

interface OwnProps {
  history: any;
}

type AppProps = StateProps & DispatchProps & OwnProps;

function App({
  loaderShown,
  user,
  checkUser,
  history,
  logout,
  languages,
  fetchLanguages,
}: AppProps) {
  useEffect(() => {
    if (!languages.length) fetchLanguages();
  }, []);

  let loggedIn = !_.isEmpty(user);
  useEffect(() => {
    if (!loggedIn) {
      checkUser({
        callbackError: () => {
          history.push("/login");
        },
      });
    }
  }, []);

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setIsAdmin(user.role === "Admin");
  }, [user]);

  return (
    <div className="App">
      <Modal />
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">Neuroventis localisation tool</Navbar.Brand>
        {loaderShown && (
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {loggedIn && (
            <Nav variant="pills" className="ml-auto">
              <Nav.Item>
                <Nav.Link as={Link} to="/versions">
                  🇺🇳 Translation Terms
                </Nav.Link>
              </Nav.Item>
              {isAdmin && (
                <>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/releases">
                      📦 Releases
                    </Nav.Link>
                  </Nav.Item>
                  <NavDropdown title="⚙️ Administration" id="admin-dropdown">
                    <NavDropdown.Item as={Link} to="/applications">
                      Applications
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/translations">
                      Translation Packs
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/users">
                      Users
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
              <Nav.Link
                onClick={() => {
                  logout({
                    callback: () => {
                      history.push("/login");
                    },
                  });
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
      <div className="content">
        {loggedIn && <Breadcrumbs />}
        <Switch>
          {!loggedIn && (
            <>
              <Route component={Login} />
              {/*<Redirect to="/login" />*/}
            </>
          )}
          {loggedIn && (
            <div className="main-content">
              <Route exact path="/applications" component={Applications} />
              <Route exact path="/applications/:name" component={Application} />
              <Route
                exact
                path="/translations/"
                component={TranslationPacksSelect}
              />
              <Route exact path="/versions/" component={Versions} />
              <Route
                exact
                path="/versions/:version"
                component={TranslationPacksSelectForVersion}
              />
              <Route
                exact
                path="/versions/:version/:translationPack"
                component={TranslationTerms}
              />
              <Route
                exact
                path="/search/"
                render={() => <Redirect to="/versions/" />}
              />
              <Route
                exact
                path="/search/:version"
                component={SearchTranslationTerms}
              />
              <Route
                exact
                path="/export-terms/:version"
                component={ExportTerms}
              />
              <Route exact path="/import-terms/:importId" component={CompareImportTerms} />
              <Route exact path="/import-terms/" component={ImportTerms} />
              <Route exact path="/releases/" component={Releases} />
              <Route exact path="/users" component={Users} />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/versions/" />}
              />
            </div>
          )}
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any): StateProps => ({
  loaderShown: state.loader,
  user: state.user.user,
  languages: state.languages,
});

const mapDispatchToProps = {
  checkUser: checkUser,
  logout: logout,
  fetchLanguages: fetchLanguages,
};

const Wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default Wrapped;
