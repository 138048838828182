import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../../app/store";

export interface Modal {
  id: string,
  form?: any
}

interface ModalState {
  modalData: Modal[],
}

const initialState = { modalData: [] } as ModalState

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    show(state, action: PayloadAction<any>) {
      state.modalData.push({...action.payload, form: ""});
    },
    hide(state, action: PayloadAction<any>) {
      state.modalData = state.modalData.filter(data => data.id !== action.payload);
    }
  },
})

export const selectModalData = (state: RootState) => state.modal.modalData;
export const { show, hide } = modalSlice.actions
export default modalSlice.reducer