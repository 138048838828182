import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { createImportTerms } from "../ImportTerms/importTermsSlice";

interface DispatchProps {
  createImportTerms;
}

interface OwnProps {
  history;
}

interface StateProps {}

type ImportTermsProps = DispatchProps & OwnProps & StateProps;

const ImportTerms = (props: ImportTermsProps) => {
  const { createImportTerms, history } = props;
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isFileValid, setIsFileValid] = useState(true);
  const [alert, setAlert] = useState({ show: false, text: "", variant: "" });
  const [termsImported, setTermsImported] = useState([]);

  const importFile = () => {
    if (!fileToUpload || fileToUpload.type !== "application/json") {
      setIsFileValid(false);
    }
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setIsFileValid(true);
        const contents = e.target.result;
        try {
          const terms = JSON.parse(contents as string);
          console.log("File contents", terms);
          if (!Array.isArray(terms)) {
            setIsFileValid(false);
            return;
          }
          const termKeys = terms.map((term) => term.key);
          if (termKeys.length === 0) {
            setIsFileValid(false);
            return;
          }
          setAlert({
            text: `Getting information about ${termKeys.length} translation terms...`,
            show: true,
            variant: "info",
          });
          setTermsImported(terms);
        } catch (err) {
          setIsFileValid(false);
          console.error("Cannot parse file", err);
        }
      };
      reader.readAsText(fileToUpload);
    } catch (err) {
      setIsFileValid(false);
      console.error("Cannot read file contents", err);
    }
  };

  useEffect(() => {
    if (termsImported.length > 0) {
      createImportTerms({
        data: {
          name: new Date().toISOString(),
          terms: termsImported,
        },
      })
        .then((response) => {
          if (response?.payload) {
            const importId = response.payload;
            console.log("Imported", importId);
            history.push(`/import-terms/${importId}`);
          } else {
            setAlert({
              show: true,
              variant: "danger",
              text: "Cannot import Translation Terms",
            });
          }
        })
        .catch(() => {
          setAlert({
            show: true,
            variant: "danger",
            text: "Cannot import Translation Terms",
          });
        });
    }
  }, [termsImported]);

  return (
    <Container>
      <Row>
        <Col>
          <h3>Import Terms</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Import JSON File</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Control
                    accept=".json"
                    type="file"
                    size="lg"
                    onChange={(e) => {
                      //@ts-ignore
                      setFileToUpload(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Button variant="primary" onClick={importFile}>
                    Import
                  </Button>
                </Form.Group>
              </Form>
              {!isFileValid && <Alert variant="danger">Invalid file</Alert>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {alert.show && <Alert variant={alert.variant}>{alert.text}</Alert>}
        </Col>
      </Row>
    </Container>
  );
};

const mapDispatchToProps: DispatchProps = {
  createImportTerms,
};

const mapStateToProps = (state: any): StateProps => ({
  languages: state.languages,
});

const Wrapped = connect(mapStateToProps, mapDispatchToProps)(ImportTerms);

export default Wrapped;
