import React from "react";
import { Accordion, Button, Card, Col, Container, Row } from "react-bootstrap";
import { ListTerm, SimpleTerm } from "../../common/types";
import ReactDiffViewer from "react-diff-viewer";
import "./diffviewer.scss";

type TermToCompare = SimpleTerm & ListTerm;

const DiffViewer = ({
  oldTerm,
  newTerm,
}: {
  oldTerm: TermToCompare;
  newTerm: TermToCompare;
}) => {
  const isList = Array.isArray(oldTerm.items) && oldTerm.items.length > 0;

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={oldTerm.key}>
            {oldTerm.key}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={oldTerm.key}>
          <Card.Body>
            {isList ? (
              <ListDiffViewer oldTerm={oldTerm} newTerm={newTerm} />
            ) : (
              <SimpleDiffViewer oldTerm={oldTerm} newTerm={newTerm} />
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const SimpleDiffViewer = ({
  oldTerm,
  newTerm,
}: {
  oldTerm: SimpleTerm;
  newTerm: SimpleTerm;
}) => {
  const newTranslations = newTerm.translations;
  const oldTranslations = oldTerm.translations;
  const languagesToCompare = Object.keys(newTranslations);
  return (
    <Container className="term-language">
      <Row>
        <Col>
          <b>Current Value</b>
        </Col>
        <Col>
          <b>New Value</b>
        </Col>
      </Row>
      {languagesToCompare.map((language) => {
        const translationInTesting =
          language in oldTranslations ? oldTranslations[language] : "";
        const newTranslation = newTranslations[language];
        return (
          <>
            <Row>
              <Col>
                <b className="translation-language" >{language}</b>
              </Col>
            </Row>
            <Col>
            { newTranslation === translationInTesting ? <em>No changes</em>
            :
              <ReactDiffViewer
                oldValue={translationInTesting}
                newValue={newTranslation}
                splitView={true}
              />
            }
            </Col>
          </>
        );
      })}
    </Container>
  );
};
const ListDiffViewer = ({
  oldTerm,
  newTerm,
}: {
  oldTerm: ListTerm;
  newTerm: ListTerm;
}) => {
  const labelsToCompare = newTerm.items.map(({ label }) => label);
  return (
    <Container className="term-language">
      {labelsToCompare.map((label) => {
        const itemInTesting = oldTerm.items.find(
          ({ label: testingLabel }) => label === testingLabel
        );
        if (itemInTesting) {
          const newTranslations = newTerm.items.find(
            ({ label: newLabel }) => label === newLabel
          );
          const languagesToCompare = Object.keys(newTranslations).filter(
            (key) => !["label", "position"].includes(key)
          );
          return (
            <>
              <Row>
                <Col>
                <b className="item-label">{label}</b>
                </Col>
              </Row>
              <Row>
                <Col>
                  {languagesToCompare.map((language) => {
                    const translationInTesting =
                      language in itemInTesting ? itemInTesting[language] : "";
                    const newTranslation = newTranslations[language];
                    return (
                      <Container>
                        <Row>
                          <Col>
                            <b className="translation-language">{language}</b>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          { translationInTesting === newTranslation ? <em>No changes</em> :
                          <ReactDiffViewer
                          oldValue={translationInTesting}
                          newValue={newTranslation}
                          splitView={true}
                        />
                          }
                            
                          </Col>
                        </Row>
                      </Container>
                    );
                  })}
                </Col>
              </Row>
            </>
          );
        }
        return <></>;
      })}
    </Container>
  );
};

export default DiffViewer;
