import React, { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";

interface OwnProps {
  searchTerms: any;
  pack?: string;
}

const SearchTerms = (props: OwnProps) => {
  const [termSearch, setTermSearch] = useState("");
  const { searchTerms } = props;
  return (
    <div className="search-terms">
      <InputGroup>
        <FormControl
          placeholder="Search Translation Terms"
          aria-label="Search Translation Terms"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              searchTerms(termSearch);
            } else {
              setTermSearch(e.target.value);
            }
          }}
        />
        <InputGroup.Append>
          <Button
            variant="outline-secondary"
            onClick={() => {
              searchTerms(termSearch);
            }}
          >
            Search
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

export default SearchTerms;
