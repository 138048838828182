import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import "./addterm.scss";

interface FormProps {
  defaultLanguage: string;
  languages: string[];
  packName: string;
  handleSubmit: any;
}

const AddForm = (props: FormProps) => {
  const [validated, setValidated] = useState(false);
  const [key, setKey] = useState("");
  const termsObject = props.languages.reduce((acc, current) => ({...acc, [current]: ''}), {});
  const [translations, setTranslations] = useState(termsObject);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    props.handleSubmit(`${props.packName}.${key}`, translations);
    setValidated(true);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group as={Row}>
        <Form.Label column sm={2}>
          Term Path
        </Form.Label>
        <Col sm={10}>
          <InputGroup hasValidation>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-sm">
                {props.packName}.
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="translation.term"
              required={true}
              onKeyUp={(e) => {
                setKey(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please specify the path.
            </Form.Control.Feedback>
          </InputGroup>
          <Form.Text className="text-muted">
            Unique identifier for this term. Only letters, dot and underscore are allowed.
          </Form.Text>
        </Col>
      </Form.Group>
      {props.languages.map((language) => {
        const isRequired = language === props.defaultLanguage;
        return (
          <Form.Group as={Row} key={language}>
            <Form.Label column sm={2} className={isRequired ? "required" : ""}>
              {language}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                required={isRequired}
                onKeyUp={(e) => {
                  setTranslations({
                    ...translations,
                    [language]: e.target.value,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please specify the translation.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        );
      })}
      <Button type="submit" variant="success" block>
        Save
      </Button>
    </Form>
  );
};

const CreateTermModal = (props: {
  show: boolean;
  handleClose: any;
  handleSave: any;
  languages: string[];
  packName: string;
  handleSubmit: any;
  defaultLanguage?: string;
}) => (
  <Modal show={props.show} onHide={props.handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Create Term</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddForm
        defaultLanguage={props.defaultLanguage || "en"}
        languages={props.languages}
        packName={props.packName}
        handleSubmit={props.handleSubmit}
      />
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
);

export default CreateTermModal;
