import React from "react";
import { Dropdown } from "react-bootstrap";

interface DropdownAction {
    action: any;
    text: string;
}

interface OwnProps {
    items: DropdownAction[];
}

const AddTermDropdown = (props: OwnProps) => (
  <Dropdown>
    <Dropdown.Toggle variant="success" id="dropdown-basic">
      Create
    </Dropdown.Toggle>

    <Dropdown.Menu>
        {props.items.map((item, index) => <Dropdown.Item as="button" onClick={item.action} key={index} >{item.text}</Dropdown.Item>)}
    </Dropdown.Menu>
  </Dropdown>
);

export default AddTermDropdown;
