import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormCheck,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { removeTerm, createExportTerms, removePack } from "./exportTermsSlice";

interface OwnProps {
  termsToExport: [];
}

interface PageParams {
  version: string;
}

interface StateProps {
  termsList: any[];
  languages: any[];
  packsList: any[];
}

interface DispatchProps {
  createExportTerms: any;
  removeTerm: any;
  removePack: any;
}

export type ExportTermsProps = StateProps & OwnProps & DispatchProps;

const ExportTerms = (props: ExportTermsProps) => {
  const { version } = useParams<PageParams>();
  const {
    languages,
    termsList,
    removeTerm,
    createExportTerms,
    packsList,
    removePack,
  } = props;
  const [selectedLanguages, setSelectedLanguages] = useState(
    languages.map(({ key }) => key)
  );

  return (
    <Container className="export-terms">
      <Row>
        <Col>
          <h2>Export Terms and Packs in Testing Version</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Select Languages</Card.Header>
            <Card.Body>
              <InputGroup
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedLanguages([...selectedLanguages, e.target.id]);
                  } else {
                    setSelectedLanguages(
                      selectedLanguages.filter((id) => id !== e.target.id)
                    );
                  }
                }}
              >
                {languages.map((language) => (
                  <FormCheck
                    key={language.key}
                    id={language.key}
                    style={{ marginRight: "5px" }}
                  >
                    <FormCheck.Input defaultChecked={true} />
                    <FormCheck.Label>{language.title}</FormCheck.Label>
                  </FormCheck>
                ))}
              </InputGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Review Items</Card.Header>
            <Card.Body>
              <Table striped bordered hover size="sm" responsive>
                {packsList.map((pack) => (
                  <tr>
                    <th>Pack</th>
                    <td>{pack.name}</td>
                    <td>
                      <Button size="sm" variant="danger" onClick={() => { removePack(pack.id) }}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
                {termsList.map((term) => (
                  <tr>
                    <th>Term</th>
                    <td>{term.key}</td>
                    <td>
                      <Button size="sm" variant="danger" onClick={() => { removeTerm(term.key) }}>
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center" style={{ marginTop: '2rem' }}>
        <Col lg={4}>
          <Button
            onClick={() => {
              createExportTerms({
                data: {
                  version,
                  languages: selectedLanguages,
                  keys: termsList.map(({ key }) => key),
                  packs: packsList,
                },
              }).then((data) => {
                const type = "application/json";
                const blob = new Blob([JSON.stringify(data.payload)], {
                  type,
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "localisation-export.json";
                link.click();
              });
            }}
            block
          >
            Download Export
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  languages: state.languages,
  termsList: state.exportTerms.termsToExport,
  packsList: state.exportTerms.packsToExport,
});

const mapDispatchToProps: DispatchProps = {
  createExportTerms,
  removePack,
  removeTerm,
};

const wrapped = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ExportTerms);

export default wrapped;
