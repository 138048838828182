import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall, ApiPayload} from "../../helpers/apiCall";

export const promoteTesting = createAsyncThunk<null, ApiPayload, null>(
  'promoteTesting',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'versions/promote/testing',
      ...payload
    }, thunkAPI);
  }
);

export const release = createAsyncThunk<null, ApiPayload, null>(
  'release',
  async (payload, thunkAPI) => {
    return await apiCall({
      method: 'post',
      url: 'versions/release/' + payload.data.version,
      ...payload
    }, thunkAPI);
  }
);
